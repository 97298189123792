import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {navigate} from 'gatsby';
import {Col, Row} from 'react-bootstrap';
import {GET_HEXOMATIC_USER} from '../../graphql/queries';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import PricingPackeges from '../../components/pricing/pricingPackeges';
import UserWrapper from '../../components/userWrap';
import Button from '../../components/button';
import SubscriptionModal from '../../components/subscriptionModal';
import shuttle from '../../images/svgIcons/shuttle.svg';
import homeMailImgLg from '../../images/home/illustrations/web-scraping-automation.png';
import homeMailImgSm from '../../images/halloween/web-scraping-automation-sm.png';
import {getShowModalonClick, getUserExists, setShowModalonClick} from '../../helpers';
import Counter from '../../components/countdown';
import TrustedSection from '../../components/trusted-section';
import '../../components/home/styles.scss';
import {useMediaQuery} from 'react-responsive';
import left from '../../images/home/left.svg';
import right from '../../images/home/right.svg';
import pumpkin from '../../images/halloween/pumpkin.png';
import bat from '../../images/halloween/bat.png';
import batMobile from '../../images/halloween/bat-mobile.png';
import spiderLG from '../../images/halloween/spider-lg.png';
import spiderSM from '../../images/halloween/spider-sm.png';
import { openCalendly } from '../../helpers/trackingEvents';

const PricingPromo = ({pageContext}: any) => {
  const [annual, setAnnual] = useState(true);
  const ltd = false;
  const [promo, setPromo] = useState(false);
  const [show, setShow] = useState(false);

  const {data: hexomaticUserInfo} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    navigate('/pricing/');
    // if (
    //   hexomaticUserInfo?.HexomaticUser?.getByLegacyId?.pricing_package &&
    //   hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package !== 'FREE' &&
    //   hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package_interval !== 'LTD'
    // ) {
    //   navigate('/pricing/');
    // }
  }, [hexomaticUserInfo]);

  const isMobileSm = useMediaQuery({maxWidth: 380});

  const textsImageLeft = [
    {label: 'Wasting time on manual work', top: isMobileSm ? '-2px' : '0px', left: isMobileSm ? '100px' : '120px'},
    {label: 'Copy-pasting all day', top: isMobileSm ? '46px' : '58px', left: isMobileSm ? '56px' : '70px'},
    {label: 'Hiring programmers', top: isMobileSm ? '46px' : '58px', left: isMobileSm ? '186px' : '226px'},
    {label: 'Outsourcing to VA’s', top: isMobileSm ? '94px' : '116px', left: isMobileSm ? '36px' : '42px'},
    {
      label: 'Subscribing to different services',
      top: isMobileSm ? '94px' : '116px',
      left: isMobileSm ? '156px' : '189px',
    },
    {label: 'Procrastinating on tasks', top: isMobileSm ? '142px' : '174px', left: isMobileSm ? '54px' : '66px'},
    {label: 'Complex enterprise tools', top: isMobileSm ? '142px' : '174px', left: isMobileSm ? '194px' : '233px'},
    {label: 'Expensive consultants', top: isMobileSm ? '190px' : '232px', left: isMobileSm ? '110px' : '132px'},
  ];
  const textsImageRight = [
    {label: 'Capture data from any website', top: isMobileSm ? '16px' : '18px', left: isMobileSm ? '60px' : '74px'},
    {label: 'Automate  tedious tasks', top: isMobileSm ? '90px' : '98px', left: isMobileSm ? '60px' : '74px'},
    {label: 'Scale your work on autopilot', top: isMobileSm ? '166px' : '178px', left: isMobileSm ? '60px' : '74px'},
  ];

  return (
    <Layout>
      <div className="pricing_promo_page home-container">
        <Section sectionImage className="upper-section" top halloween>
          <Col className="title-section" lg={6}>
            <h1 className="title position-relative">
              <img
                src={pumpkin}
                style={{position: 'absolute', top: '-100px', left: '0px'}}
                className="d-xl-flex d-none"
              />

              <div className="primary-color position-relative">
                <img
                  src={bat}
                  className="d-none d-xl-flex"
                  style={{position: 'absolute', top: '20px', right: '10px'}}
                />
                Web scraping + work{' '}
                <img src={batMobile} className="d-lg-none" style={{width: '38px', height: 'auto'}} />
              </div>
              <div className="primary-color">
                automation, <span className="dark-color">made easy</span>
              </div>
            </h1>
            <div className="d-lg-none d-flex justify-content-center">
              <img
                src={homeMailImgSm}
                srcSet={`${homeMailImgSm} 257w, ${homeMailImgSm} 590w`}
                sizes="(max-width: 585px) 257px, (min-width: 585.98px) 590px"
                alt="Web scraping + work automation"
                className="section-image-iframe"
                style={{maxWidth: '386px'}}
                width={386}
                height={294}
              />
            </div>
            <div className="title-pagehero">
              Get ready to have your socks scared off with our spooktacular Halloween promo!
            </div>
            <Row className="m-lg-0 upper-section-btn-pos">
              <Col md={7} className="p-2">
                <Button
                  primary
                  className="btn w-100"
                  onClick={() => navigate(`${global?.window?.location?.pathname || ''}#deal`)}
                >
                  Secure your lifetime deal
                </Button>
              </Col>
              <Col md={4} className="p-2">
                <Button className="btn demo-request-btn w-100" onClick={openCalendly} outline>
                  Demo request
                </Button>
              </Col>
              <Col md={12} className="text-center text-lg-left">
                <UserWrapper
                  withoutUser={() => (
                    <span
                      className="clickable-text"
                      onClick={() => {
                        if (getUserExists() === 'true' || getShowModalonClick() === 'false') {
                          window.open('https://hexomatic.com/the_growth_hacking_playbook.pdf', '_blank');
                        } else {
                          setShowModalonClick('true');
                          setShow(true);
                        }
                      }}
                    >
                      Get our growth hacking playbook <img src={shuttle} alt="shuttle" />
                    </span>
                  )}
                  withLoading={() => (
                    <span
                      className="clickable-text"
                      onClick={() => {
                        if (getUserExists() === 'true' || getShowModalonClick() === 'false') {
                          window.open('https://hexomatic.com/the_growth_hacking_playbook.pdf', '_blank');
                        } else {
                          setShowModalonClick('true');
                          setShow(true);
                        }
                      }}
                    >
                      Get our growth hacking playbook <img src={shuttle} alt="shuttle" />
                    </span>
                  )}
                  withUser={() => (
                    <span
                      className="clickable-text"
                      onClick={() => {
                        window.open('https://hexomatic.com/the_growth_hacking_playbook.pdf', '_blank');
                      }}
                    >
                      Get our growth hacking playbook <img src={shuttle} alt="shuttle" />
                    </span>
                  )}
                />
              </Col>
            </Row>
            <img
              src={spiderSM}
              style={{position: 'absolute', bottom: '20px', left: '180px'}}
              className="d-xl-flex d-none"
            />
          </Col>
          <Col className="d-lg-flex d-none justify-content-end" lg={6}>
            <div className="position-relative">
              <img
                src={homeMailImgLg}
                srcSet={`${homeMailImgSm} 257w, ${homeMailImgLg} 555w`}
                sizes="(max-width: 585px) 257px, (min-width: 585.98px) 555px"
                alt="Web scraping + work automation"
                className="section-image-iframe"
                width={555}
                height={420}
              />
              <img src={spiderLG} style={{position: 'absolute', top: '10px', right: '130px'}} />
            </div>
          </Col>
        </Section>
        <Section className="pricing-section">
          <div className="col-12 my-5 mx-auto" id="deal">
            <h2 className="f_size_26 f_700 t_color3 l_height45 mt_10 mb-20 text-center">
              Halloween Lifetime deal + Claim your free{' '}
              <span className="primary-color">“Done For You” concierge session</span> 🎃
            </h2>
            <div className="text-center">
              For a limited time only, we’re offering lifetime access to Hexomatic for a one time fee PLUS we are
              throwing in a free “done for you” concierge session!
            </div>
            <div className="text-center">
              Our team of not-so-scary tech ghouls will swoop in and set up your first workflow or scraping recipe for
              you, making sure you’re all set to cast your data magic.
            </div>
            <Counter date="Nov 1 2023 07:00:00 GMT" title="DEAL ENDS IN..." className="lg" />
          </div>

          <div className="pricing-section mx-auto mt-xl-5 mb-4">
            <PricingPackeges
              interval={ltd ? 'ltd' : annual ? 'annual' : 'monthly'}
              products={pageContext.products}
              promo={promo}
              LTD
              promoPage
            />
          </div>
        </Section>
        <Section className="py-40">
          <Col>
            <div className="section-part">
              <h2 className="title">
                <p className="concept-title">SKYROCKET YOUR PRODUCTIVITY</p>
                Your <span className="primary-color">virtual assistant</span>
                <br />
                that never sleeps
              </h2>

              <div className="row w-100" style={{maxWidth: '1000px'}}>
                <div className="col-12 col-lg-5 p-0">
                  <div className="mb-4">
                    <div className="concept-title">THE OLD WAY</div>
                    <div className="concept-title-dark">Drowning in tasks</div>
                  </div>

                  <div className="position-relative" style={{maxWidth: isMobileSm ? '300px' : '360px', margin: 'auto'}}>
                    <img
                      src={left}
                      alt="Drowning in tasks"
                      className="justify-content-center image-styles"
                      style={{
                        maxWidth: isMobileSm ? '300px' : '360px',
                        margin: 'auto',
                        width: isMobileSm ? '300px' : '100%',
                      }}
                      width={isMobileSm ? 300 : 360}
                      height={290}
                    />
                    {textsImageLeft.map(item => (
                      <div
                        style={{
                          position: 'absolute',
                          top: item.top,
                          left: item.left,
                          fontSize: isMobileSm ? '7px' : '8.7px',
                          color: '#516077',
                          fontWeight: 500,
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 col-lg-2 d-flex p-0">
                  <div className="vs-line-main mx-auto mt-auto"></div>
                </div>

                <div className="col-12 col-lg-5 p-0">
                  <div className="mb-4">
                    <div className="concept-title">THE NEW WAY</div>
                    <div className="concept-title-dark">10X your productivity</div>
                  </div>
                  <div className="position-relative" style={{maxWidth: isMobileSm ? '300px' : '320px', margin: 'auto'}}>
                    <img
                      src={right}
                      alt="10X your productivity"
                      className="justify-content-center image-styles"
                      style={{maxWidth: '320px', margin: 'auto', width: isMobileSm ? '300px' : '100%'}}
                      width={isMobileSm ? 300 : 320}
                      height={260}
                    />
                    {textsImageRight.map(item => (
                      <div
                        style={{
                          position: 'absolute',
                          top: item.top,
                          left: item.left,
                          fontSize: isMobileSm ? '12px' : '14px',
                          color: '#050038',
                          fontWeight: 500,
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Section>
        <TrustedSection />
      </div>
      {show && <SubscriptionModal handleSetShow={(arg: boolean) => setShow(arg)} isModalOpen={show} />}
    </Layout>
  );
};

export default PricingPromo;
